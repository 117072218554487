/**
 *
 * Layouts
 *
 */

import clsx from 'clsx';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { memo } from 'react';
import MuiAlert from '@material-ui/lab/Alert';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from './slice';
import { layoutsSaga } from './saga';
import { sidebar } from 'routes/adminRoutesList';
import { IRoute } from 'routes/types';
import AppsIcon from '@material-ui/icons/Apps';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useHistory } from 'react-router';
import { Snackbar } from '@material-ui/core';
import { selectLayouts } from './selectors';
import { SnackBarMessage } from 'models/snack-bar';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import AndroidIcon from '@material-ui/icons/Android';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  mainSinglePage: {
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    // width: `calc(100% - ${drawerWidth}px)`,
    width: '100%',
    padding: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface Props {
  children: React.ReactNode;
  minimal?: boolean;
}

export const Layouts = memo((props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: layoutsSaga });
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const layouts = useSelector(selectLayouts);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { children, minimal } = props;
  const [selectdRoute, setSelectedRoute] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<SnackBarMessage>(
    {
      type: 'success',
      message: '',
    },
  );
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRedirectRoute = (event, path) => {
    dispatch(actions.hideSnackBar());
    if (history.location.pathname === path) {
      event.preventDefault();
    } else {
      history.push(path);
    }
  };

  const generateIcon = icon => {
    switch (icon) {
      case 'AppsIcon':
        return <AppsIcon />;
      case 'AmpStoriesIcon':
        return <AmpStoriesIcon />;
      case 'AddBoxIcon':
        return <AddBoxIcon />;
      case 'AssessmentIcon':
        return <AssessmentIcon />;
      case 'PlaylistAddCheckIcon':
        return <PlaylistAddCheckIcon />;
      case 'AndroidIcon':
        return <AndroidIcon />;
      default:
        return <FiberNewIcon />;
    }
  };

  const onCloseSnackBar = () => {
    setOpenSnack(false);
    dispatch(actions.hideSnackBar);
  };

  const handleClose = () => {
    setOpenBackDrop(false);
    dispatch(actions.hideBackDrop);
  };

  React.useEffect(() => {
    if (layouts.snackBarMessage) {
      setSnackBarMessage(layouts.snackBarMessage);
      setOpenSnack(true);
    } else {
      setOpenSnack(false);
    }
  }, [layouts.snackBarMessage, snackBarMessage]);

  React.useEffect(() => {
    if (layouts.backDrop) {
      setOpenBackDrop(true);
    } else {
      setOpenBackDrop(false);
    }
  }, [layouts.backDrop]);

  return (
    <div className={classes.root}>
      {minimal ? (
        <>
          <div className={classes.mainSinglePage}>{children}</div>
        </>
      ) : (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Bukizi Admin
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              {sidebar.map((route: IRoute, index) => {
                return (
                  <ListItem
                    // button
                    selected={selectdRoute === route.id}
                    key={route.id}
                    onClick={event => {
                      setSelectedRoute(route.id);
                      handleRedirectRoute(event, route.path);
                    }}
                  >
                    <ListItemIcon>{generateIcon(route.icon)}</ListItemIcon>
                    <ListItemText primary={route.title} />
                  </ListItem>
                );
              })}
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div>{children}</div>
          </main>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnack}
        autoHideDuration={5000}
        onClose={onCloseSnackBar}
      >
        <MuiAlert variant="filled" severity={snackBarMessage.type}>
          {snackBarMessage.message}
        </MuiAlert>
      </Snackbar>

      <Backdrop
        className={classes.backdrop}
        open={openBackDrop}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
});
