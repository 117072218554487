/**
 *
 * Asynchronously loads the component for ControlPanel
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ControlPanel = lazyLoad(
  () => import('./index'),
  module => module.ControlPanel,
);
