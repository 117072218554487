/* eslint-disable import/no-anonymous-default-export */
const PREFIX = `local::`;

function set<T = object>(key: string, value: T): void {
  if (!localStorage) {
    return;
  }

  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(PREFIX + key, serializedValue);
  } catch (error) {
    throw new Error('store serialization failed');
  }
}

function get<T = object>(key: string): T | undefined {
  if (!localStorage) {
    return;
  }

  try {
    const serializedValue = localStorage.getItem(PREFIX + key);
    if (!serializedValue) {
      return;
    }
    return JSON.parse(serializedValue);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

function removeItem(key: string) {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(PREFIX + key);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

function removeAllItem() {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.clear();
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

export const OAUTH_TOKEN = 'ih_access_token';
export const REFRESH_TOKEN = 'ih_refresh_token';
export const PROVIDER_ID = 'provider_id';
export const AVATAR_ID = 'avatar_id';
export const IS_UPDATED = 'is_updated';
export const ANONYMOUS_ID = 'anonymousId';
export const CUSTOM_DISPLAY_CONSUMER_LIST = 'custom_display_consumer_list';
export const ROLE = 'profile_role';
export const SIDEBAR_OPEN = 'sidebar_open';

export default {
  get,
  set,
  removeItem,
  removeAllItem,
  OAUTH_TOKEN,
  REFRESH_TOKEN,
  PROVIDER_ID,
  ANONYMOUS_ID,
  IS_UPDATED,
  AVATAR_ID,
  CUSTOM_DISPLAY_CONSUMER_LIST,
  ROLE,
  SIDEBAR_OPEN,
};
