import { createGlobalStyle } from 'styles/styled-components';
import { primaryColor } from 'styles/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IGlobalStyle {
  background?: string;
  fontFamily?: string;
}

const GlobalStyle = createGlobalStyle<IGlobalStyle>`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    // background-color: ${props => props.background};
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .MuiPickersBasePicker-container {
    border: 1px solid #EA5C5F;
    border-radius: 4px;
  }
  .MuiCheckbox-colorPrimary.Mui-disabled {
      color: #CDBEFC;
  }
  .ck-editor__editable {
    min-height: 300px;
  }
  .MuiCheckbox-root {
    color: ${primaryColor};
  }
  .MuiRadio-root {
    color: ${primaryColor};
  }
  .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  },
`;

const useGlobalStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  fillAvailable: {
    flex: 1,
  },
  fillWidth: {
    width: '100%',
  },
}));

export default GlobalStyle;
export { useGlobalStyles };
