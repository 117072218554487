/**
 *
 * Asynchronously loads the component for ClientRequest
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ClientRequest = lazyLoad(
  () => import('./index'),
  module => module.ClientRequest,
);
