import { PayloadAction } from '@reduxjs/toolkit';
import { SnackBarMessage } from 'models/snack-bar';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the Layouts container
export const initialState: ContainerState = {
  title: '',
  snackBarMessage: undefined,
  backDrop: undefined,
};

const layoutsSlice = createSlice({
  name: 'layouts',
  initialState,
  reducers: {
    showSnackbar(state, action: PayloadAction<SnackBarMessage>) {
      state.snackBarMessage = action.payload;
    },
    hideSnackBar(state, action: PayloadAction) {
      state.snackBarMessage = undefined;
    },
    openBackDrop(state, action: PayloadAction) {
      state.backDrop = true;
    },
    hideBackDrop(state, action: PayloadAction) {
      state.backDrop = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = layoutsSlice;
export const {
  showSnackbar,
  hideSnackBar,
  openBackDrop,
  hideBackDrop,
} = actions;
