import { Authentication } from 'app/containers/Authentication/Loadable';
import { Dashboard } from 'app/containers/Dashboard/Loadable';
import { AddPackage } from 'app/containers/AddPackage/Loadable';
import { PackagesList } from 'app/containers/PackagesList/Loadable';
import { ControlPanel } from 'app/containers/ControlPanel/Loadable';
import { ClientRequest } from 'app/containers/ClientRequest/Loadable';
import { AssignedScenario } from 'app/containers/AssignedScenario/Loadable';
import { path } from './path';
import { IRoute } from './types';

const authRoutes: IRoute = {
  id: 'auth',
  path: path.signIn,
  component: Authentication,
  private: false,
};
const dashboardRoutes: IRoute = {
  id: 'dashboard',
  path: path.dashboard,
  component: Dashboard,
  private: true,
  title: 'Khách hàng',
  icon: 'AssessmentIcon',
};
const addPackage: IRoute = {
  id: 'addpackage',
  path: path.addNew,
  component: AddPackage,
  private: true,
  title: 'Thêm gói',
  icon: 'AddBoxIcon',
};

const packagesList: IRoute = {
  id: 'packages',
  path: path.packages,
  component: PackagesList,
  private: true,
  icon: 'AmpStoriesIcon',
  title: 'Danh sách gói',
};

const controlPanel: IRoute = {
  id: 'controlPanel',
  path: path.panel,
  component: ControlPanel,
  icon: 'AppsIcon',
  private: true,
  title: 'Bảng điều khiển',
};
const clientRequest: IRoute = {
  id: 'clientRequest',
  path: path.clientRequest,
  component: ClientRequest,
  icon: 'PlaylistAddCheckIcon',
  private: true,
  title: 'Danh sách yêu cầu',
};
const assignedScenario: IRoute = {
  id: 'assignedScenario',
  path: path.assignedScenario,
  component: AssignedScenario,
  icon: 'AndroidIcon',
  private: true,
  title: 'Kịch bản chat bot',
};

export const auth = [authRoutes];
export const panel = [controlPanel];
export const addpackage = [addPackage];
export const dashboard = [dashboardRoutes];
export const packageslist = [packagesList];
export const clientrequest = [clientRequest];
export const assignedscenario = [assignedScenario];
export const sidebar = [
  controlPanel,
  dashboardRoutes,
  packagesList,
  addPackage,
  clientRequest,
  assignedScenario,
];
