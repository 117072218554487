import { Layouts } from 'app/containers/Layouts';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  addpackage,
  auth,
  dashboard,
  packageslist,
  sidebar,
  panel,
  clientrequest,
} from './adminRoutesList';
import { childRoutes } from './helper';
import { path } from './path';

const FullRoutes = () => (
  <Switch>
    <Redirect exact from="/" to={path.dashboard} />
    <Route exact from="/" component={Dashboard} />
    <Route path="/auth" component={Auth} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/packages" component={PackagesList} />
    <Route path="/add_new" component={AddPackage} />
    <Route path="/client_request" component={ClientRequest} />
    <Route path="/" component={SideBar} />
  </Switch>
);

export const Auth = () => {
  return (
    <Layouts minimal={true}>
      <Switch>
        {childRoutes(auth)}
        <Redirect to={path.signIn} />
      </Switch>
    </Layouts>
  );
};

export const Dashboard = () => {
  return (
    <Layouts>
      <Switch>
        {childRoutes(dashboard)}
        <Redirect to={path.signIn} />
      </Switch>
    </Layouts>
  );
};

export const PackagesList = () => {
  return (
    <Layouts>
      <Switch>
        {childRoutes(packageslist)}
        <Redirect to={path.packages} />
      </Switch>
    </Layouts>
  );
};

export const AddPackage = () => {
  return (
    <Layouts>
      <Switch>
        {childRoutes(addpackage)}
        <Redirect to={path.signIn} />
      </Switch>
    </Layouts>
  );
};

export const ClientRequest = () => {
  return (
    <Layouts>
      <Switch>
        {childRoutes(clientrequest)}
        <Redirect to={path.signIn} />
      </Switch>
    </Layouts>
  );
};

export const ControlPanel = () => {
  return (
    <Layouts>
      <Switch>
        {childRoutes(panel)}
        <Redirect to={path.signIn} />
      </Switch>
    </Layouts>
  );
};

export const SideBar = () => {
  return (
    <Layouts minimal={false}>
      <Switch>
        {childRoutes(sidebar)}
        <Redirect to={path.signIn} />
      </Switch>
    </Layouts>
  );
};

export default FullRoutes;
