import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';
import { IRoute, IItemRoute } from './types';
import { path as routerPath } from './path';

const requiredAuth = (privateRouter?: boolean) => {
  return privateRouter === true && !isAuthenticated();
};

const RouteItem = (route: IRoute) => {
  const { component: Component } = route;
  return (
    <Route
      key={route?.id}
      path={route?.path}
      exact
      render={props => {
        if (requiredAuth(route.private) === true) {
          return (
            <Redirect
              to={{
                pathname: routerPath.signIn,
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

const childRoutes = routes => {
  const result = routes.map((item: IRoute) =>
    item?.children
      ? item?.children.map((item: IItemRoute, index) => RouteItem(item))
      : RouteItem(item),
  );
  return result;
};

export { childRoutes };
