import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.layouts || initialState;

export const selectLayouts = createSelector(
  [selectDomain],
  layoutsState => layoutsState,
);
