/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import FullRoutes from 'routes';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/global-styles';
import { theme } from 'styles/styled-components';

// import GlobalStyle from '../styles/global-styles';

export function App() {
  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Bukizi Admin" defaultTitle="Bukizi Admin">
        <meta name="description" content="A Bukizi Admin application" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <FullRoutes />
      </ThemeProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}
