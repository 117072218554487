import { generatePath } from 'react-router';

const path = {
  dashboard: '/dashboard',
  custome: '/customers',
  packages: '/packages',
  panel: '/control',
  addNew: '/add_new',
  configuration: '/configuration',
  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  notFound: '/not-found',
  accessDenied: '/access-denied',
  clientRequest: '/client-request',
  assignedScenario: '/assigned-scenario',
};

const createPath = (pattern: string, params: any) =>
  generatePath(pattern, params);

export { path, createPath };
